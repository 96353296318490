@tailwind base;
@tailwind components;
@tailwind utilities;

/* Do not convert yet */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  --border-width: 2px;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  @apply bg-gray-800 overlay md:overflow-x-hidden;
}



a:focus-visible,
button:focus-visible,
link:focus-visible,
input:focus-visible,
textarea:focus-visible {
  @apply ring-4 ring-white ring-opacity-50 outline-none;
}
.hljs {
  color: #adbac7;
  background: #1f2937 !important;
}
code:focus-visible {
  outline: none;
}
.overlay {
  overflow: overlay;
}
.clipped {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-clip-path: url(#my-clip-path);
  clip-path: url(#my-clip-path);
}
.multiGradient {
  background: linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0);
  transition: box-shadow 0.2s;
}

.multiGradient:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}
.footer-border {
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  border-top: solid var(--border-width) transparent;
}
.footer-border:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: var(--border-width);
  left: 0;
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.gradient-border:before,
.gradient-border:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  background: linear-gradient(90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  border-radius: inherit;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-500 rounded transition-colors shadow-2xl hover:bg-gray-600 active:bg-gray-700;
}

/* CONVERT TO TAILWIND */
.-z-1 {
  z-index: -1;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  line-height: 1.1rem;
}

.tooltip .tooltiptext {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s ease-in-out, visiblity 0s linear 0.15s;
  width: 300px;
  backdrop-filter: blur(5px);
  background-color: #000a;
  color: #ddd;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 115%;
  /* margin-left: -60px; */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
